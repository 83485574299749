import React, { createContext, useContext, useState  } from "react";

const defaultContext = {
  captchaToken: null,
  captchaError: false
}

export const CaptchaContext = createContext(defaultContext);

const CaptchaProvider = ({ children }) => {

  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);


  return (
    <CaptchaContext.Provider value={{captchaToken, setCaptchaToken, captchaError, setCaptchaError}}>
      {children}
    </CaptchaContext.Provider>
  );
};

export function useCaptchaValue(){
  return useContext(CaptchaContext)
}

export default CaptchaProvider;